<template>
  <div>
    <ds-header title="资讯类型管理"></ds-header>
    <a-form layout="inline">
      <a-form-item label="类型名称">
        <a-input v-model="searchForm.consult_type_name" style="width: 200px" placeholder="请输入类型名称"/>
      </a-form-item>
      <a-form-item label="所属项目:">
        <a-select placeholder="请选择项目" mode="multiple" v-model="searchForm.project_id_list" style="width: 200px"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode">
          <a-select-option v-for="option in projectList" :key="option.id" :value="option.id">
            {{ option.project_name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <span>
        <a-button type="primary" @click="getList('search')" style="margin-top: 3px"> <a-icon type="search" />查询 </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm">重置</a-button>
      </span>
    </a-form>
    <div style="padding-top: 15px">
      <a-button type="primary" @click="addType"><a-icon type="plus" />新增资讯类型</a-button>
    </div>
    <div style="padding-top: 15px">
      <a-table
        :rowKey="(record, index) => index"
        :columns="table.columns"
        :data-source="table.dataInfo"
        :pagination="table.pagination.total ? table.pagination : false"
        @change="pageChange"
        bordered
      >
        <span slot="consult_img" slot-scope="text, record" class="action">
          <div class="imgContainer">
            <img :src="record.consult_img" />
          </div>
        </span>
        <span slot="action" slot-scope="text, record" class="action">
          <a-button type="link" class="btnClass" @click="edit(record)"> 编辑 </a-button>
          <a-button type="link" class="btnClass" @click="deleteById(record)"> 删除 </a-button>
        </span>
      </a-table>
    </div>
    <a-modal
      title="新增资讯类型"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :dialog-style="{ top: '40%' }"
      @ok="handleOk"
      @cancel="handleCancel"
      :destroyOnClose="true"
      :maskClosable="false"
    >
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
      >
        <a-form-model-item label="资讯类型名称" prop="consult_type_name">
          <a-input
            v-model.trim="form.consult_type_name"
            placeholder="请输入资讯类型名称，不超过6个字符"
            :maxLength="6"
          />
        </a-form-model-item>
        <a-form-model-item label="所属项目" prop="project_id_list">
          <!-- <a-select placeholder="请选择榜单项目" mode="multiple" v-model="form.project_id_list"
                    :getPopupContainer="(triggerNode) => triggerNode.parentNode">
            <a-select-option v-for="option in projectList" :key="option.id" :value="option.id">
              {{ option.project_name }}
            </a-select-option>
          </a-select> -->
          <el-cascader v-model="form.project_id_list"
              style="width: 100%;"
              ref="cascader"
              :collapse-tags = "true"
              :options="projectList"
              :props="{emitPath:false, multiple: true,checkStrictly:true, label: 'project_name', children: 'child', value: 'id' }" size="small"
              clearable>
        </el-cascader>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {addType, deleteTypeById, editType, getConsultingTypeList, getTypeItemById,getShunyiAllProject} from "@/api/consultingManagement";
import {Modal} from "ant-design-vue";

export default {
  name: 'ConsultingType',
  computed: {
    ...mapState({
      // projectList: (state) => state.common.projectList
    }),
  },
  data() {
    return {
      // form表单
      form: {
        // 资讯类型id
        consult_type_id: '',
        // 资讯类型名称
        consult_type_name: '',
        // 所属项目
        project_id_list: []
      },
      rules: {
        consult_type_name: [
          { required: true, message: '请输入资讯类型名称', trigger: 'blur' }
        ],
        project_id_list: [
          { required: true, message: '请选择所属项目', trigger: 'change' }
        ]
      },
      // 弹框
      visible: false,
      // 弹框异步关闭
      confirmLoading: false,
      searchForm: {
        // 类型名称
        consult_type_name: '',
        // 所属项目
        project_id_list: []
      },
      table: {
        dataInfo: [],
        columns: [
          {
            title: "资讯类型名称",
            dataIndex: "consult_type_name",
          },
          {
            title: '所属项目',
            dataIndex: 'project_name_str'
          },
          {
            title: '最后修改时间',
            dataIndex: 'update_at'
          },
          {
            title: "最后修改人",
            dataIndex: "update_name",
          },
          {
            title: '创建时间',
            dataIndex: 'create_at'
          },
          {
            title: "创建人",
            dataIndex: "create_name",
          },
          {
            title: "操作",
            key: "action",
            width: "200px",
            className: "action-ql-list",
            scopedSlots: { customRender: "action" },
          },
        ],
        //分页数据
        pagination: {
          showSizeChanger: true,
          current: 1,
          pageSize: 10,
          total: 0,
          showQuickJumper: true,
          showTotal: (total) => {
            return `共 ${total} 条`;
          },
        },
      },
      projectList:[]
    }
  },
  async created() {
    // 查询
    await this.getList()
    getShunyiAllProject({ check_type: true }).then(res => {
      this.projectList = res.data
      console.log(res, '================>');
    })
  },
  methods: {
    // 查询
    async getList(type) {
      const params = this.getParams()
      if(type =='search'){
        params.page = 1
        this.table.pagination.current = 1
      }
      const res = await getConsultingTypeList(params);
      const { code, data: { list, total }, msg } = res
      if (code === '200') {
        this.table.dataInfo = list
        this.table.pagination.total = total
      } else {
        this.$message.error(msg)
      }
    },
    // 整合参数
    getParams() {
      return {
        ...this.searchForm,
        page: this.table.pagination.current,
        size: this.table.pagination.pageSize
      }
    },
    // 重置查询条件
    resetSearchForm() {
      this.table.pagination.current = 1;
      this.table.pagination.pageSize = 10;
      this.searchForm = {
        consult_type_name: '',
        project_id_list: []
      };
      this.getList();
    },
    // 分页操作
    pageChange(option) {
      this.table.pagination.current = option.current;
      this.table.pagination.pageSize = option.pageSize;
      this.getList();
    },
    // 新增资讯类型
    addType() {
      this.resetForm()
      this.visible = true
    },
    // 去新增或修改
    async handleOk() {
      let res = {}
      if (this.form.consult_type_id) {
          res = await editType(this.form)
      } else {
        res = await addType(this.form);
      }
      this.confirmLoading = true
      const { code, msg } = res
      if(code === '200') {
        this.$message.success(this.form.consult_type_id ? '修改成功！' : '添加成功！')
        this.confirmLoading = false
        this.visible = false
        this.table.pagination.current = 1
        this.table.pagination.pageSize = 10
        await this.getList()
      } else {
        this.$message.error(msg)
        this.confirmLoading = false
      }
    },
    // 编辑
    async edit(row) {
      const id = row.consult_type_id
      this.resetForm()
      const res = await getTypeItemById(id)
      const { code, data } = res
      if (code === '200') {
        this.form.consult_type_id = data.consult_type_id
        this.form.consult_type_name = data.consult_type_name
        this.form.project_id_list = data.project_id_list
        this.visible = true
      }
    },
    // 删除
    async deleteById(row) {
      const id = row.consult_type_id
      const that = this
      Modal.confirm({
        title: '确定要删除吗?',
        okText: '确认',
        okType: 'danger',
        cancelText: '取消',
        async onOk() {
          const res = await deleteTypeById(id)
          const {code, msg} = res
          if (code === '200') {
            that.$message.success('删除成功！')
            that.table.pagination.current = 1
            await that.getList()
          } else {
            that.$message.error(msg)
          }
        },
        onCancel() {
          console.log('Cancel');
        },
      })
    },
    // 重置form表单的数据
    resetForm() {
      this.form.consult_type_id = ''
      this.form.consult_type_name = ''
      this.form.project_id_list = []
    },
    // 取消
    handleCancel() {
      this.visible = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
